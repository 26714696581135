import { ConnectionStatus, DirectLine } from "botframework-directlinejs";
import React, { Component } from "react";
import Analytics from "./Analytics";
import "./ChatApp.css";
import ChatBubble from "./ChatBubble";
import getChatStore from "./ChatStore";
import ChatWindow from "./ChatWindow";
import ChatWindowParticipantTagger from "./ChatWindowParticipantTagger";
import Conversation from "./Conversation";
import ConversationStatus from "./ConversationStatus";
import ChatAppExtensions from "./Extensions/ChatAppExtensions";
import { GLOBAL_MENU_EXT_ID } from "./Extensions/GlobalMenuExtension/GlobalMenuExtension";
import UserInfo from "./UserInfo";

/**
 * Main chat app, runs with the page
 * 
 * iid = integration id, used to identify this particular bot's frontend integration 
 (generally 1 bot = 1 integration; some may have multiple separate integrations)
 */
export default class ChatApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatBindings: {
        enableChat: this.enableChat.bind(this),
        disableChat: this.disableChat.bind(this),
        endChat: this.end.bind(this),
        getState: this.getState.bind(this),
        getChatState: this.getChatState.bind(this),
        getIntegrationInfo: this.getIntegrationInfo.bind(this),
        setConnectionStatus: this.setConnectionStatus.bind(this),
        scrollChat: this.scrollChat,
        setExtension: this.setExtension.bind(this),
        sendAkoTokenDetailToBotAndDB:
          this.sendAkoTokenDetailToBotAndDB.bind(this), //Token Method
        getExtension: (extensionName) => this.state.extensions[extensionName],
      },
    };
    const iid = props.iid;
    // 'lstore' is the localstorage (thanks for that name, Jed)
    // which we use to store certain aspects of the bot, for each integration.
    const lStore = {
      OPENED: iid + "-opened",
      IDENTITY: iid + "-identity",
      CHAT_STATE: iid + "-chatstate",
      CREDENTIALS: iid + "-credentials",
      COOKIES: iid + "-cookies",
      EXTENSIONS: iid + "-extensions",
    };

    let loadedExtensions;
    try {
      loadedExtensions = JSON.parse(localStorage.getItem(lStore.EXTENSIONS));
    } catch {}

    if (!loadedExtensions) {
      loadedExtensions = {};
    }

    this.state = {
      lStore: lStore,
      opened: localStorage.getItem(lStore.OPENED) === "open",
      messages: [],
      directline: undefined,
      started: false,
      chatBindings: this.state.chatBindings,
      inputEnabled: true,
      animation: {
        bubbleToFadeIn: true,
      },
      integrationSettings: undefined,
      extensions: {
        ...loadedExtensions,
        _api: ChatAppExtensions.getAPI(this, this.props),
      },
      connectionError: false,
      tokenSentTODB: true,
    };

    // getChatStore needs state.lStore to be set first for it to work
    this.state.store = getChatStore(this.state.chatBindings);

    // Tell parent iframe opened state
    this.state.opened
      ? this.postMessage("chatwindowOpen")
      : this.postMessage("chatwindowClose");

    // Listen to messages
    window.addEventListener("message", this.windowMessage.bind(this), false);
  }

  /**
   * Get conversation token/bot details on mount
   */
  componentDidMount() {
    if (!this.state.started) {
      this.fetchTokenAndSettings();
    }
  }

  /**
   * Resets the chat
   */
  resetChat() {
    new ChatWindowParticipantTagger().clearTags();
    this.closed();
    this.setState({
      store: getChatStore(this.state.chatBindings, true),
      inputEnabled: true,
      started: false,
    });
  }

  /**
   * Listens to component changes, sends opened status to parent frame for resizing
   * (frame on client site must shrink to not cover content when chat minimised)
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.opened !== this.state.opened) {
      this.state.opened
        ? this.postMessage("chatwindowOpen")
        : this.postMessage("chatwindowClose");
    }
  }

  setExtension(extensionName, payload) {
    let extensions = {
      ...this.state.extensions,
      [extensionName]: payload,
    };
    this.setState({ extensions });

    localStorage.setItem(
      this.state.lStore.EXTENSIONS,
      JSON.stringify(extensions)
    );
  }

  /**
   * Handles messages from the window (i.e. from the containing iframe)
   */
  windowMessage(event) {
    if (!this.state.opened && event.data === "chatwindowOpened") {
      this.opened();
    }
    if (typeof event.data === "string" && event.data.startsWith("AKO_TOKEN:")) {
      localStorage.setItem("AKO_TOKEN", event.data.replace("AKO_TOKEN:", ""));
      this.sendAkoTokenDetailToBotAndDB();
      this.postMessage("AKO_TOKEN_RECEIVED");
    }
    if (typeof event.data === "string" && event.data === "AKO_TOKEN_REMOVE") {
      this.removeAkoTOkenFromSlots();
    }
    if (typeof event.data === "string" && event.data.startsWith("message:")) {
      this.opened();

      setTimeout(() => {
        this.state.store.dispatch({
          type: "WEB_CHAT/SEND_MESSAGE",
          payload: {
            text: event.data.replace("message:", ""),
          },
        });
      }, 4000);
    }

    if (
      typeof event.data === "string" &&
      event.data.startsWith("update-styling:")
    ) {
      let payload = event.data.substr(15);
      this.setState({ integrationSettings: JSON.parse(payload) });
    }

    if (
      typeof event.data === "string" &&
      event.data === "clearCachedSettings"
    ) {
      this.end();
    }
  }

  /**
   * Scrolls chat to bottom, achieves this slightly messily by selecting using CSS selectors
   */
  scrollChat() {
    var els = document.getElementsByClassName("css-y1c0xs");

    for (var el of els) {
      if (el.classList.contains("css-ca0rlf")) {
        el.scrollTop = el.scrollHeight;
      }
    }
  }

  /**
   * Posts a message to the parent window, if this window has one
   * @param {*} msg
   */
  postMessage(msg) {
    if (window.parent) {
      window.parent.postMessage(msg, "*");
    }
  }

  end() {
    this.setState({
      extensions: { _api: ChatAppExtensions.getAPI(this, this.props) },
    });
    localStorage.removeItem(this.state.lStore.EXTENSIONS);

    let conversationHelper = new Conversation(
      this.state.lStore,
      this.props.iid,
      this.resetChat.bind(this)
    );
    conversationHelper.end();
    this.closed();

    this.setState({ directline: undefined });

    // Load settings for next conversation (if any)
    setTimeout(this.fetchTokenAndSettings.bind(this), 50);
  }

  /**
   * Various setters/getters for children to take as props
   * Mostly used by the ChatStore.js
   */
  enableChat() {
    this.setState({ inputEnabled: true });
  }
  disableChat() {
    //lets just... not
    //this.setState({ inputEnabled: false });
  }

  getState() {
    return this.state.store.getState();
  }
  getChatState() {
    return this.state;
  }
  getIntegrationInfo() {
    return {
      iid: this.props.iid,
      host: this.props.host,
      path: this.props.path,
      title: this.props.title,
    };
  }
  sendAkoTokenDetailToBotAndDB() {
    let token = localStorage.getItem("AKO_TOKEN");
    if (token && this.state.directline && this.state.opened) {
      let userInfo = new UserInfo();
      setTimeout(
        () => userInfo.sendUserLoggedinToken(this.state.directline),
        1500
      ); //Send Token To DB
      setTimeout(() => {
        this.state.store.dispatch({
          type: "WEB_CHAT/SEND_EVENT",
          payload: {
            name: "ako/ako_token",
            value: token,
          },
        });
      }, 500); //Send Token To Slot
      this.setState({
        tokenSentTODB: false, //token send to slots and db
      });
    }
  }
  removeAkoTOkenFromSlots() {
    localStorage.removeItem("AKO_TOKEN");
    setTimeout(() => {
      this.state.store.dispatch({
        type: "WEB_CHAT/SEND_EVENT",
        payload: {
          name: "ako/ako_token_remove",
          value: null,
        },
      });
    }, 500); //S
    this.postMessage("USER_LOGGED_OUT");
  }
  setConnectionStatus(status) {
    if (
      status === ConnectionStatus.FailedToConnect ||
      status === ConnectionStatus.ExpiredToken ||
      status === ConnectionStatus.Ended
    ) {
      this.setState({ connectionError: true });
    } else {
      this.setState({ connectionError: false });
    }
  }

  opened() {
    this.setState({ opened: true });
    localStorage.setItem(this.state.lStore.OPENED, "open");

    if (!this.state.directline) {
      if (this.state.token) {
        this.startDirectline(this.state.token, this.state.integrationSettings);
      }
    }
  }

  /**
   * Gets a conversation token and the integration settings for the bot and saves them in state
   * If the chat window is open, it will then start the conversation
   */
  fetchTokenAndSettings() {
    this.setState({ started: true });

    let conversationHelper = new Conversation(
      this.state.lStore,
      this.props.iid,
      this.resetChat.bind(this)
    );
    conversationHelper
      .setupConversationDetails()
      .then((conversationDetails) => {
        this.setState({
          integrationSettings: conversationDetails.integrationSettings,
          token: conversationDetails.token,
        });

        let analytics = new Analytics(
          conversationDetails.integrationSettings,
          this.props.iid,
          this.props.host,
          this.props.path,
          this.props.title
        );
        const enableMobileNudges =
          conversationDetails.integrationSettings.enable_mobile_nudges;

        if (
          !this.props.mobile ||
          typeof enableMobileNudges === "undefined" ||
          enableMobileNudges === true
        ) {
          analytics.startNudges();
        }

        if (conversationDetails.integrationSettings.menu) {
          this.setExtension(
            GLOBAL_MENU_EXT_ID,
            conversationDetails.integrationSettings.menu
          );
        }

        if (this.state.opened) {
          this.startDirectline(
            conversationDetails.token,
            conversationDetails.integrationSettings
          );
        }
      });
  }

  /**
   * Called to start directline and other components of conversation
   */
  async startDirectline(token, integrationSettings) {
    // To Run Local BotLoader
    // npm install -g offline-directline
    // directline -d 3200 -b "http://127.0.0.1:3978/api/messages"
    // domain: "http://127.0.0.1:3200/directline"  set domain var like this in direct line

    let directline = new DirectLine({ token: token });
    this.setState({ integrationSettings, directline });
    localStorage.setItem(
      this.state.lStore.CREDENTIALS,
      JSON.stringify({
        token: token,
        integrationSettings: integrationSettings,
      })
    );
    let analytics = new Analytics(
      integrationSettings,
      this.props.iid,
      this.props.host,
      this.props.path,
      this.props.title
    );
    analytics.setupAnalytics(directline);
    setTimeout(() => this.sendAkoTokenDetailToBotAndDB(), 5000);
    let conversationStatus = new ConversationStatus(
      integrationSettings,
      this.props.iid,
      this.state.chatBindings
    );
    conversationStatus.setupStatusPolling();
  }

  closed() {
    this.setState({ opened: false });
    localStorage.removeItem(this.state.lStore.OPENED);
  }

  /**
   * Renders either the ChatWindow or the "ChatBubble" (bubble, pop up messages, etc visible when chat isn't)
   */
  render() {
    const {
      opened,
      directline,
      store,
      inputEnabled,
      animation,
      integrationSettings,
    } = this.state;
    const { mobile } = this.props;

    return (
      <div>
        {integrationSettings ? (
          <div>
            <ChatWindow
              botid={this.props.iid}
              opened={opened}
              onClosed={this.closed.bind(this)}
              directline={directline}
              store={store}
              inputEnabled={inputEnabled}
              endClicked={this.end.bind(this)}
              cookies_state_store={this.state.lStore.COOKIES}
              mobile={mobile}
              setBubbleToShrinkIn={() => {
                this.setState({
                  animation: { ...this.state.animation, bubbleToFadeIn: false },
                });
              }}
              integrationSettings={integrationSettings}
              extensions={this.state.extensions}
              connectionError={this.state.connectionError}
              api={ChatAppExtensions.getAPI(this, this.props)}
            />
            {!opened && (
              <ChatBubble
                onOpened={this.opened.bind(this)}
                fadeIn={animation.bubbleToFadeIn}
                integrationSettings={integrationSettings}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
