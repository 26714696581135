import React, { Component } from 'react';
import "./FileUploadCard.css";

export default class FileUploadCard extends Component {
    constructor (props) {
        super(props);

        this.state = {
            files: [],
            error: undefined,
            used: props.content.used
        }

        this.uploadRef = React.createRef();
        this.selectFile = this.selectFile.bind(this);
        this.filesChanged = this.filesChanged.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    selectFile () {
        if (this.uploadRef.current) {
            this.uploadRef.current.click();
        }
    }

    filesChanged () {
        let fileList = this.uploadRef.current?.files;
        this.setState({ files: fileList ? Object.values(fileList) : [] });
    }

    async uploadFiles () {
        this.setState({ error: undefined })

        let attachments = [];
        let file = this.state.files[0];

        const formData = new FormData();

        formData.append('file', file);

        let uploadR = await fetch(this.props.content.uploadUrl, {
            method: 'POST',
            body: formData
        });

        if (!uploadR.ok) {
            if (uploadR.status === 422) {
                this.setState({ error: 'File is too large or of wrong format.' })
            } else {
                this.setState({ error: 'Unknown error.' })
            }
            return;
        }

        let uploadId = (await uploadR.text()).replaceAll("\"", "");

        attachments.push({
            contentType: 'ako/file-upload-response',
            content: {
                uploadId
            }
        });

        this.props.api.sendActivity({
            type: 'DIRECT_LINE/POST_ACTIVITY',
            payload: {
                activity: {
                    type: 'event',
                    name: 'ako/file-upload-response',
                    value: uploadId
                }
            },
            meta: {}
        });

        this.setState({ files: [], used: true })
    }

    render () {
        let content = this.props.content ?? {};
        let isMulti = content.allowMultiple;
        let accept = content.accept ?? "";
        let { 
            files,
            error,
            used
        } = this.state;

        return (
            <div className="upload-container">
                {
                    used ?
                    <i> File Uploaded </i>
                    :
                    <div className="btn btn-outline-secondary w-100" onClick={this.selectFile}> 
                        { isMulti ? "Select Files" : "Select File" }
                    </div>
                }
                <input
                    type="file"
                    className="file-upload-hidden"
                    onChange={this.filesChanged}
                    ref={this.uploadRef}
                    accept={accept}
                    multiple={isMulti}/> 
                { error ?
                    <div class="file-upload-error"> { error } </div>
                    : ''
                }
                {
                    files.length > 0 ? 
                        <div>
                            <div className="w-100 mt-3 mb-2">
                                { files.length } file(s) selected:
                                {
                                    files.map(f => (
                                        <div key={f.name}> { f.name } </div>
                                    ))
                                }
                            </div>
                            <div className="btn btn-outline-success w-100" onClick={this.uploadFiles}> 
                                Upload
                            </div>
                        </div>
                    :
                    ''
                }
            </div>
        )
    }
}