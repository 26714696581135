var webViewApiExtEnabled = false;
var webViewApiEnabled = false;
var webViewApi;

export function enableWebViewApi(api) {
    webViewApiEnabled = true;
    webViewApi = api;
}

export function disableWebViewApi() {
    webViewApiEnabled = false;
}

export function enableWebViewApiExt(api) {
    webViewApiExtEnabled = true;
    webViewApi = api;
}

export function disableWebViewApiExt() {
    webViewApiExtEnabled = false;
}

function handleWebViewApi(event) {
    if (!webViewApiEnabled && !webViewApiExtEnabled) {
        return;
    }

    let message = event.data;
    if (!message.startsWith('webview-api:')) {
        return;
    }

    let payload = JSON.parse(message.substring(12));
    
    switch (payload.type) {
        case 'sendMessage':
            webViewApi.sendActivity({
                type: 'WEB_CHAT/SEND_MESSAGE',
                payload: { text: payload.message }
            })
            break;
        default:
            console.error(`Didn't understand web view API call type: ${payload.type}`)
    }
}
window.addEventListener('message', handleWebViewApi)