import React, { Component } from 'react';
import './ChatSuggestionExtension.css';

export const CHAT_SUGGESTION_EXT_ID = "CHAT_SUGGESTION";

export default class ChatSuggestionExtension extends Component {
    constructor(props) {
        super();

        this.state = {
            searchTerm: '',
            bottomMargin: 0,
            filteredSuggestions: [],
            maximised: false
        };

        if (props.suggestions) {
            props.suggestions.getSuggestionCallback = this.processUserInput.bind(this);
        }

        this.onSuggestionClicked.bind(this);

        // Finds the input box and listens to it
        // Hacky, but WebChat doesn't expose an API for listening to input
        this.findInputTimerId = setInterval(() => {
            let chatbox = document.getElementById("chat_box");
            if (!chatbox) {
                return;
            }
            let inputs = chatbox.querySelectorAll("input");
            let inputRegion = chatbox.querySelectorAll("[role='form']")
            if (inputs.length < 1 || inputRegion.length < 1) {
                return;
            }

            this.setState({
                bottomMargin: inputRegion[0].clientHeight
            });
            this.updateSuggestions(inputs[0].value);

            inputs[0].addEventListener("input", (event) => {
                this.updateSuggestions(event.target.value);
            });

            clearInterval(this.findInputTimerId);
        }, 50);
    }

    componentDidUpdate(oldProps) {
        if (!this.props.suggestions) {
            return;
        }

        if (oldProps.suggestions.time !== this.props.suggestions.time) {
            this.updateSuggestions(this.state.searchTerm);
            this.setState({ maximised: false });
            this.props.suggestions.getSuggestionCallback = this.processUserInput.bind(this);
        }
    }

    processUserInput(input) {
        if (!this.props.suggestions) {
            return;
        }

        let filtered = this.props.suggestions.options
            .filter(suggestion => this.doesSuggestionMatch(suggestion, input));

        let match;
        if (filtered.length === 1) {
            match = filtered[0].name;
        } else {
            let exactMatch = filtered.find(suggestion => suggestion.name.toLowerCase() === input.toLowerCase() ||
                (suggestion.synonyms && suggestion.synonyms.some(syn => syn.toLowerCase() === input.toLowerCase())));

            match = exactMatch ? exactMatch.name : undefined;
        }

        if (!match && filtered.length < 1) {
            this.updateSuggestions('');
        }
        return match;
    }

    updateSuggestions(text) {
        this.setState({
            searchTerm: text,
            maximised: (text.length > 0),
            filteredSuggestions: (this.props.suggestions.options) ? this.props.suggestions.options
                .filter(suggestion => this.doesSuggestionMatch(suggestion, text)) : []
        });
    }

    doesSuggestionMatch(suggestion, text) {
        let searchable = [...(suggestion.synonyms ? suggestion.synonyms.map(syn => syn.toLowerCase()) : []), suggestion.name.toLowerCase()];

        return searchable.some(term => term.startsWith(text.toLowerCase()));
    }

    onSuggestionClicked(suggestion) {
        this.props.api.sendActivity({
            type: 'WEB_CHAT/SEND_MESSAGE',
            payload: { text: suggestion }
        });
    }

    render() {
        const { color } = this.props.styling;

        return <div className='chat_suggestion_container' style={{ height: 'calc(100% - ' + this.state.bottomMargin + 'px)' }}>
            {
                this.state.maximised ?
                    <div className='chat_suggestion_results'>
                        <div className='chat_suggestion_header'>
                            {this.props.suggestions.question}
                            <div className='chat_suggestion_header_minimise'
                                style={{ borderColor: color.header }}
                                onClick={() => { this.setState({ maximised: !this.state.maximised }) }}>
                                {this.state.maximised ? "Hide" : ''}
                            </div>
                        </div>
                        {
                            this.state.filteredSuggestions.map(suggestion =>
                                <div className='chat_suggestion_item' key={suggestion.name} onClick={() => { this.onSuggestionClicked(suggestion.name) }}>
                                    <div className='chat_suggestion_item_inner'>
                                        <div className='chat_suggestion_item_select_box'
                                            style={{ borderColor: color.header }}> </div>
                                        {suggestion.name}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    ''
            }
        </div>
    }
}