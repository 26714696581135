import React, { Component } from 'react';
import './ChatBubble.css';


export default class ChatBubble extends Component {
    constructor(props) {
        super(props);

        this.bubble_clicked = this.bubble_clicked.bind(this);
        this.nudge_clicked = this.nudge_clicked.bind(this);
    }

    bubble_clicked() {
        this.props.onOpened();
    }

    nudge_clicked() {
        this.props.onOpened();
    }

    getChatBubbleClasses(fadeIn, left) {
       
        let classes = ['chat_bubble'];

        if (fadeIn) {
            classes.push('chat_bubble_fade');
        }
        else {
            classes.push('chat_bubble_shrink');
        }

        if (left) {
            classes.push('chat_bubble_left');
        }
        if (this.props.integrationSettings.chatBubbleSize) {
            switch (this.props.integrationSettings.chatBubbleSize) {
                case 'Small': classes.push('chat_bubble_small');
                    break;
                case 'Large': classes.push('chat_bubble_large');
                    break;
                default:
            }
        }
        return classes.join(' ');
    }

    getChatAreaClasses(left) {
        let classes = ['chat_area'];

        if (left) {
            classes.push('chat_area_left');
        }

        return classes.join(' ');
    }

    render() {
        return (
            <div className={this.getChatAreaClasses(this.props.integrationSettings.align_right === false)} onClick={this.bubble_clicked}>
                <div
                    className={this.getChatBubbleClasses(this.props.fadeIn, this.props.integrationSettings.align_right === false)}
                    style={{ backgroundImage: `url(${this.props.integrationSettings.bot_icon_url})` }}
                >
                </div>
            </div>
        )
    }

}