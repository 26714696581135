const INTEGRATION_STATUS_URL =
  process.env.REACT_APP_CHATDB_ENDPOINT + "/api/public/webbubble/status";

export default class ConversationStatus {
  constructor(integrationSettings, iid, chatCallbacks) {
    this.integrationSettings = integrationSettings;
    this.iid = iid;
    this.chatCallbacks = chatCallbacks;
  }

  setupStatusPolling() {
    if (
      this.integrationSettings.offline_messages &&
      this.integrationSettings.offline_messages !== ""
    ) {
      try {
        // Assume that the bot is online, to start with
        window.AKO_BOT_status = "online";

        // Check bot status every 8s
        window.setInterval(this.checkStatus.bind(this), 8000);
        this.checkStatus();
      } catch {
        //  console.log(this.integrationSettings);
      }
    }
  }

  checkStatus() {
    fetch(`${INTEGRATION_STATUS_URL}?integration=${this.iid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.iid}`,
      },
    }).then((res) => {
      res.json().then((res) => {
        const status = res.status;
        // If the status has just changed to offline, send a fake message from the 'bot'
        // that informs the user about the outage.
        if (status === "offline" && window.AKO_BOT_status === "online") {
          this.sendOfflineMessages();

          this.chatCallbacks.disableChat();
          window.AKO_BOT_status = status;
        } else if (status === "online") {
          this.chatCallbacks.enableChat();
        }
      });
    });
  }

  sendOfflineMessages() {
    let messages = JSON.parse(this.integrationSettings.offline_messages);

    for (let msg of messages) {
      this.chatCallbacks.getChatState().store.dispatch({
        type: "DIRECT_LINE/INCOMING_ACTIVITY",
        payload: {
          activity: {
            type: "message",
            id: Math.random() + "",
            timestamp: new Date().toString(),
            text: msg,
            from: {
              id: "",
              name: "",
              role: "bot",
            },
            conversation: {
              id: "",
            },
          },
        },
      });
    }
  }
}
