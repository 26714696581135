var INTERVAL_TIMER;

export default class ChatWindowParticipantTagger {
    constructor (botName, botImage) {
        this.botName = botName;
        this.botImage = botImage;
    }

    start () {
        if (INTERVAL_TIMER) {
            clearInterval(INTERVAL_TIMER);
        }
        INTERVAL_TIMER = setInterval(this.addParticipantTags.bind(this), 200);
    }

    // Adds tags for user/bot above each block of messages
    addParticipantTags() {
        let msChatwindow = document.querySelector("#chat_box .webchat__basic-transcript div ul");
        
        if (msChatwindow) {
            let messageBubbles = msChatwindow.children;

            for (let row = 0; row < messageBubbles.length; row++) {
                // Ignore tags
                if (messageBubbles[row].className.includes('participant-tag')) {
                    continue;
                }

                // Check if this is a message at the start of a block
                // (by checking if a message AND previous message didn't exist or was the last of a block)
                let isFirst = (row === 0);
                let isFirstOfBlock = false;
                let alreadyHasTag = false;
                
                if (!isFirst) {
                    if (messageBubbles[row].querySelector(".webchat__bubble--from-user")) {
                        isFirstOfBlock = !messageBubbles[row - 1].querySelector(".webchat__bubble--from-user");
                    }
                    else {
                        isFirstOfBlock = messageBubbles[row - 1].querySelector(".webchat__bubble--from-user");
                    }

                    alreadyHasTag = messageBubbles[row - 1].className.includes('participant-tag');
                }

                if ((isFirst || isFirstOfBlock) && !alreadyHasTag) {
                    let tag;
                    // Check if this is a user/bot block
                    if (messageBubbles[row].querySelector('.webchat__bubble--from-user')) {
                        tag = this.getUserTag();
                    }
                    else {
                        tag = this.getBotTag();
                    }

                    msChatwindow.insertBefore(tag, messageBubbles[row]);
                }
            }
        }
        else {
            // console.error(`Participant Tagger: Couldn't find the chatWindow.`);
        }
    }

    getUserTag() {
        let tag = document.createElement("li");
        tag.innerHTML = "You";
        tag.className = "participant-tag participant-tag-user";
        tag.setAttribute('role', 'listitem');
        return tag;
    }

    getBotTag() {
        let tag = document.createElement("li");
        tag.innerHTML = `<div class='participant-tag-bot-bubble' style="background-image: url('${this.botImage}')"> </div> 
            <div class='participant-tag-bot-text'> ${this.botName} </div>`;
        tag.className = "participant-tag participant-tag-bot";
        tag.setAttribute('role', 'listitem');
        return tag;
    }

    // Called when chat reset so the tags don't get orphaned and sit around
    clearTags() {
        let tags = document.querySelectorAll('.participant-tag');
        for (let tag of tags) {
            tag.remove();
        }
    }
}