import React, { Component } from 'react';
import { disableWebViewApiExt, enableWebViewApiExt } from '../WebViewExtension/WebViewApi';
import { WEB_VIEW_EXT_ID } from '../WebViewExtension/WebViewExtension';

export const WEB_VIEW_EX_EXT_ID = "WEB_VIEW_EXTERNAL";

export default class WebViewExternalExtension extends Component {
    constructor (props) {
        super(props);

        if (props.webviewExt) {
            if (props.webviewExt.enableApis) {
                enableWebViewApiExt(props.api);
            }
            else {
                disableWebViewApiExt();
            }

            if (props.api.isMobile()) {
                this.openInternalView(props.webviewExt.title, props.webviewExt.url, props.api);
            }
            else {
                this.openExternalView(props.webviewExt, props.styling);
            }
        }

        window.addEventListener("message", this.receiveWindowMessage.bind(this), false);
    }

    receiveWindowMessage(event) {
        if (typeof event.data === 'string' && event.data.startsWith('closeWebView')) {
            this.props.api.setExtension(WEB_VIEW_EX_EXT_ID, undefined);
        }

        if (typeof event.data === 'string' && event.data.startsWith('fallbackWebView')) {
            this.props.api.setExtension(WEB_VIEW_EXT_ID, { 
                title: this.props.webviewExt.title, 
                url: this.props.webviewExt.url, 
                height: 100
            });

            setTimeout(() => { this.props.api.setExtension(WEB_VIEW_EX_EXT_ID, undefined) }, 10); 
        }
    }

    componentDidUpdate(oldProps) {
        if (!this.props.opened) {
            this.closeExternalView();
        }

        if (!this.props.webviewExt) {
            return;
        }

        if (!oldProps.webviewExt ||
                oldProps.webviewExt.title !== this.props.webviewExt.title || 
                oldProps.webviewExt.url !== this.props.webviewExt.url) {
            
            if (this.props.api.isMobile()) {
                this.openInternalView(this.props.webviewExt.title, this.props.webviewExt.url, this.props.api);
            }
            else {
                this.openExternalView(this.props.webviewExt, this.props.styling);
            }
        }
    }

    componentWillUnmount() {
        this.closeExternalView();
    }

    openInternalView(title, url, api) {
        // Switch to inline view (for mobile)
        api.setExtension(WEB_VIEW_EXT_ID, {
            ...this.props.webviewExt,
            largeWindow: undefined,
        });
        setTimeout(() => api.setExtension(WEB_VIEW_EX_EXT_ID, undefined), 10); // Immediate successive calls cause state issues :(
    }

    openExternalView(settings, styling) {
        let payload = {
            title: settings.title,
            url: settings.url,
            styling,
            hideHeader: settings.largeWindowHideHeader
        };
        
        this.postMessage("openWebView:" + JSON.stringify(payload));
    }

    closeExternalView() {
        this.postMessage("closeWebView");
    }

    render () {
        return <div></div>
    }

    postMessage(msg)  {
        if (window.parent)  {
            window.parent.postMessage(msg, '*');
        }
    }
}