const INTEGRATION_DETAIL_URL =
  process.env.REACT_APP_CHATDB_ENDPOINT + "/api/v2/webbubble/config";
const MS_REFRESH_URL =
  "https://directline.botframework.com/v3/directline/tokens/refresh";

export default class Conversation {
  constructor(localStoreNames, iid, resetChat) {
    this.localStoreNames = localStoreNames;
    this.iid = iid;
    this.resetChat = resetChat;
  }

  /**
   * Attempts to restore any stored chat, otherwise will fetch a new token and restart
   */
  async setupConversationDetails() {
    // Existing token
    try {
      var stored = JSON.parse(
        localStorage.getItem(this.localStoreNames.CREDENTIALS)
      );
      var hideMyBot = localStorage.getItem(this.iid + "-hideBotCID");
      if (stored && stored.token && stored.integrationSettings) {
        // Attempt to refresh the token
        var msg = await fetch(MS_REFRESH_URL, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + stored.token,
          },
        });
        var body = await msg.json();

        if (msg.status === 200 && body && body.token) {
          //Post Message For Style
          if (window.parent) {
            window.parent.postMessage(
              "style:" + JSON.stringify(stored.integrationSettings),
              "*"
            );
            if (
              hideMyBot &&
              new Date(
                new Date() - new Date(parseInt(hideMyBot))
              ).getMinutes() < 15 &&
              new Date(parseInt(hideMyBot)).getDate() === new Date().getDate()
            ) {
              window.parent.postMessage("HideBot", "*");
            } else {
              localStorage.removeItem(this.iid + "-hideBotCID");
            }
          }

          return {
            token: body.token,
            integrationSettings: stored.integrationSettings,
          };
        }
      }
    } catch {}

    // If session has expired/never existed
    this.resetChat();
    const res = await fetch(INTEGRATION_DETAIL_URL + "?iid=" + this.iid, {
      method: "GET",
    });

    const data = await res.json();
    var token = data.token.token;
    //Post Message For Style
    if (window.parent) {
      window.parent.postMessage("style:" + JSON.stringify(data.style), "*");
    }
    localStorage.removeItem(this.iid + "-hideBotCID");
    return { token: token, integrationSettings: data.style };
  }

  /**
   * Deletes stored credentials and restarts
   */
  end() {
    localStorage.removeItem(this.localStoreNames.CHAT_STATE);
    localStorage.removeItem(this.localStoreNames.CREDENTIALS);
  }
}
