import React, { Component } from 'react';
import { disableWebViewApi, enableWebViewApi } from '../WebViewExtension/WebViewApi';
import './WebViewExtension.css';


export const WEB_VIEW_EXT_ID = "WEB_VIEW";

export default class WebViewExtension extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bottomMargin: 0,
            title: props.webview.title,
            url: props.webview.url,
            height: props.webview.height,
            hideWindow: props.webview.largeWindowHideHeader
        }

        if (props.webview.enableApis) {
            enableWebViewApi(props.api);
        } else {
            disableWebViewApi();
        }

        // Resize box to sit above input
        this.findInputTimerId = setInterval(() => {
            let chatbox = document.getElementById("chat_box");
            if (!chatbox) {
                return;
            }
            let inputs = chatbox.querySelectorAll("input");
            let inputRegion = chatbox.querySelectorAll("[role='form']")
            if (inputs.length < 1 || inputRegion.length < 1) {
                return;
            }

            this.setState({
                bottomMargin: inputRegion[0].clientHeight
            });

            clearInterval(this.findInputTimerId);
        }, 50);

        // Set up message listeners for frame
        window.addEventListener("message", (event) => {
            if (event.data === 'areYouAko') {
                document.getElementById('web-view-frame').contentWindow.postMessage("youAreInAko", "*");
            }
        });

        this.close = this.close.bind(this);
    }

    componentDidUpdate(oldProps) {
        if (!this.props.webview) {
            return;
        }

        if (!oldProps.webview ||
            oldProps.webview.title !== this.props.webview.title ||
            oldProps.webview.url !== this.props.webview.url ||
            oldProps.webview.height !== this.props.webview.height) {
            this.setState({
                title: this.props.webview.title,
                url: this.props.webview.url,
                height: this.props.webview.height,
                hideWindow: false
            });

            if (this.props.webview.enableApis) {
                enableWebViewApi(this.props.api);
            } else {
                disableWebViewApi();
            }
        }
    }

    close() {
        this.setState({
            closing: true
        });
        // Allow animation to play
        setTimeout(() => {
            this.setState({
                closing: false
            });

            this.props.api.setExtension(WEB_VIEW_EXT_ID, undefined);
        }, 800);
    }

    render() {
        const { color } = this.props.styling;
        let containerStyling = {
            bottom: '0px',
        //     height: 'calc(' + this.state.height + '% - ' + this.state.bottomMargin + 'px)',
            height: '100%', 
            animationName: this.state.closing ? 'slide-down' : 'slide-up'
        };
        return <div className='web-view-container' style={containerStyling} >
            {!this.state.hideWindow ? <div className='web-view-header' style={{ backgroundColor: color.header, color: color.header_text }}>
                {this.state.title}
            </div> : null}

            <div className='web-view-close' onClick={this.close}>
                <svg style={{ fill: color.header_buttons || 'black' }} className='web-view-close-svg'>
                    <use xlinkHref="/res/defs.svg#svg-icon-times"></use>
                </svg>
            </div>
            <div className='web-view-inner-container'>
                <iframe src={this.state.url} title='Chat Web View' className='web-view-frame' id='web-view-frame' />
            </div>
        </div>
    }
}