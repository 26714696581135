import { createStore } from "botframework-webchat";
import { CHAT_SUGGESTION_EXT_ID } from "./Extensions/ChatSuggestionExtension/ChatSuggestionExtension";
import { WEB_VIEW_EXT_ID } from "./Extensions/WebViewExtension/WebViewExtension";
import { WEB_VIEW_EX_EXT_ID } from "./Extensions/WebViewExternalExtension/WebViewExternalExtension";

let lastSlots = {};

// Suggestions waiting for user input to be shown
let waitingSuggestedActions = null;
/**
 * Activity from Microsoft Directline
 */
function incomingActivity(dispatch, next, action, callbacks) {
    // Enable the input box when the first message from the bot arrives
    callbacks.enableChat(); 
    callbacks.scrollChat();

    // Set the bot's status to online
    window.AKO_BOT_status = 'online';

    let activity = action.payload.activity;
    if (isRecentActivity(action) && activity && activity.type === 'event') {
        if (activity.name === 'end-webchat') {
            callbacks.endChat();
        }
    }

    if (isRecentActivity(action) && activity && activity.type === 'message') {
        waitingSuggestedActions = null;
    }

    if (isRecentActivity(action) && activity && activity.channelData) {
        // Fancy suggestion UI
        if (action.payload.activity.channelData.suggestionUi) {
            action.payload.activity.suggestedActions = []; // hide dafault suggestion style
    
            let suggestionsPayload = action.payload.activity.channelData.suggestionUi;
            suggestionsPayload.time = Date.now();
            suggestionsPayload.question = action.payload.activity.text;
            callbacks.setExtension(CHAT_SUGGESTION_EXT_ID, suggestionsPayload);
        }

        // Suggestions that pop up when user starts typing
        if (activity.channelData.delayButtonsUntilTyping) {
            waitingSuggestedActions = activity.suggestedActions.actions;
            activity.suggestedActions = {}
        }
        // if (activity.channelData.uiOption) {
        //     //CSS Modification for Suggestion button
        //     callbacks.setExtension("SUGGESTION_UI", activity.channelData.uiOption)
        // }

        // Web View
        if (action.payload.activity.channelData.webView) {
            let webviewPayload = action.payload.activity.channelData.webView;
            if (webviewPayload.largeWindow) {
                callbacks.setExtension(WEB_VIEW_EX_EXT_ID, webviewPayload);
            } else {
                callbacks.setExtension(WEB_VIEW_EXT_ID, webviewPayload);
            }
        }

        // Debug view for slots
        if (action.payload.activity.channelData.debug && action.payload.activity.channelData.debug.slots) {
            let slots = action.payload.activity.channelData.debug.slots;
            console.log(`Bot Debug (d: ${action.payload.activity.channelData.debug.dialog}, m: ${action.payload.activity.text}) slots: `, slots);

            let changed = Object.keys(slots).filter(key => key !== '__last_message' && slots[key] !== lastSlots[key]);
            changed.forEach(key => console.log(`Bot Debug, Slot: ${key}: ${lastSlots[key]} -> ${slots[key]}`));

            let removed = Object.keys(lastSlots).filter(key => typeof slots[key] === 'undefined');
            removed.forEach(key => console.log(`Bot Debug, Slot: ${key}: ${lastSlots[key]} -> undefined`));

            lastSlots = slots;
        }
    }

    if (action.payload && action.payload.activity && action.payload.activity.attachments) {
        filterAttachments(dispatch, action.payload.activity, callbacks);
    }


    return next(action);
}

function isRecentActivity(action) {
    if (!(action.payload && action.payload.activity && action.payload.activity.timestamp)) {
        return false;
    }

    let time = new Date(action.payload.activity.timestamp);

    return (Date.now() - time.valueOf()) < 3000;
}

/**
 * Manages custom handling of attachments for new activities
 */
function filterAttachments(dispatch, activity, callbacks) {
    if ((Date.now() - Date.parse(activity.timestamp)) > 10000) {
        return; // Assume anything sent >10s ago is stale (e.g. reloaded when app opened)
    }
}

/**
 * Activity going outwards, normally to Microsoft Directline
 */
function outgoingActivity(dispatch, next, action, callbacks) {
    if (action.payload && action.payload.text) {
        let extension = callbacks.getExtension(CHAT_SUGGESTION_EXT_ID);

        if (extension && extension.getSuggestionCallback) {
            let suggestion = extension.getSuggestionCallback(action.payload.text);
        
            if (suggestion) {
                action.payload.text = suggestion;
                callbacks.setExtension(CHAT_SUGGESTION_EXT_ID, undefined);
            } else {
                return;
            }
        }
    }

    return next(action);
}

/**
 * When directline first connects
 */
function onConnect(dispatch, next, action, callbacks) {

    let integrationInfo = callbacks.getIntegrationInfo();
    if (callbacks.getChatState().integrationSettings.enable_analytics === true) {
        dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
                name: 'ako/analytics',
                value: {
                    language: 'en-US',
                    user_agent: window.navigator.userAgent,
                    page_host: integrationInfo.host,
                    page_path: integrationInfo.path,
                    page_title: integrationInfo.title
                }
            }
        });
    }

    if (callbacks.getState().activities && callbacks.getState().activities.length > 1) {
        // Chat already ready
        return;
    }

    dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
            name: 'webchat/join',
            value: {
                language: 'en-US',
                iid: integrationInfo.iid,
                user_agent: window.navigator.userAgent,
                page_host: integrationInfo.host,
                page_path: integrationInfo.path,
                page_title: integrationInfo.title
            }
        }
    });
}

function setSuggestedActions(dispatch, next, action, callbacks) {
    if (!action.payload.suggestedActions || action.payload.suggestedActions.length < 1) {
        return next(action);
    }

    setTimeout(() => next(action), 500);
}

function onTyping(dispatch, next, action, callbacks) {
    if (action.payload.text && waitingSuggestedActions) {
        dispatch({
            type: 'WEB_CHAT/SET_SUGGESTED_ACTIONS',
            payload: {
                suggestedActions: waitingSuggestedActions
            }
        })
        waitingSuggestedActions = null;
    }

    return next(action);
}

function importStore(callbacks) {
    var stored;
    try {
        stored = localStorage.getItem(callbacks.getChatState().lStore.CHAT_STATE);
        stored = JSON.parse(stored);

        processStoredActivities(stored)
    }
    catch (e) {

    }
    finally {
        if (!stored) {
            stored = {};
        }
        if (typeof stored !== 'object') {
            stored = {};
        }
    }
    return stored;
}

// Some activities should be modified when they are reloaded from storage
// (this gives them different behaviour than when sent in the first place)
function processStoredActivities(stored) {
    for (let activity of stored.activities) {
        if (activity.attachments) {
            for (let attachment of activity.attachments) {
                if (attachment.contentType === 'ako/file-upload-prompt') {
                    attachment.content.used = true;
                }
            }
        }
    }
}


/**
 * Creates the Redux store used by the MS Chat widget
 * Some additional functionality is implemented as middleware, all in this file
 */
export default function getChatStore(chatCallbacks, forceNewState) {
    var stored = forceNewState ? {} : importStore(chatCallbacks);

    // We use the 'createStore()' Bot framework function to create a store with our middleware
    // injected into it.
    let store = createStore(stored, ({ dispatch }) => next => action => {
        if (!action) {
            return next(action);
        }

        if (action.type === 'DIRECT_LINE/UPDATE_CONNECTION_STATUS' && typeof action.payload?.connectionStatus === 'number') {
            chatCallbacks.setConnectionStatus(action.payload.connectionStatus)
        }

        switch (action.type) {
            case 'DIRECT_LINE/CONNECT_FULFILLED':
                onConnect(dispatch, next, action, chatCallbacks);
                break;
            case 'DIRECT_LINE/INCOMING_ACTIVITY':
                return incomingActivity(dispatch, next, action, chatCallbacks);
            case 'WEB_CHAT/SEND_MESSAGE':
                return outgoingActivity(dispatch, next, action, chatCallbacks);
            case 'WEB_CHAT/SET_SUGGESTED_ACTIONS':
                return setSuggestedActions(dispatch, next, action, chatCallbacks);
            case 'WEB_CHAT/SET_SEND_BOX':
                return onTyping(dispatch, next, action, chatCallbacks);
            default:
                // Nothing to do
                break;
        }

        localStorage.setItem(
            chatCallbacks.getChatState().lStore.CHAT_STATE,
            JSON.stringify(chatCallbacks.getState())
        );
        return next(action);
    });

    return store;
}