import React from 'react';
import CustomHeroCard from '../MsUiComponents/CustomHeroCard';
import FileUploadCard from './FileUploadExtension/FileUploadCard';

export const AttachmentMiddleware = (api) => () => next => card => {
    switch (card.attachment.contentType) {
      case 'application/vnd.microsoft.card.hero':
        return (
          <CustomHeroCard herocard={card.attachment.content} api={api}/>
        );
      case 'ako/file-upload-prompt':
        return (
          <FileUploadCard content={card.attachment.content} api={api}/>
        );
  
      default:
        return next(card);
    }
  };