import { ConnectionStatus } from 'botframework-directlinejs';

const SEND_LOGGED_USER_TOKEN_URL = process.env.REACT_APP_CHATDB_ENDPOINT + '/api/v2/analytics/identity/'
export default class UserInfo {

    constructor() {
        this.userToken = localStorage.getItem('AKO_TOKEN');
    }
    async sendUserLoggedinToken(directLine) {
        directLine.connectionStatus$.subscribe(async connectionStatus => {
            if (connectionStatus === ConnectionStatus.Online) {
                if (directLine.conversationId && this.userToken) {

                    let identity = [];
                    identity.push({
                        key: 'AKO_USER_TOKEN',
                        value: this.userToken
                    });
                    try {
                        await fetch(SEND_LOGGED_USER_TOKEN_URL, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                conversation_id: directLine.conversationId,
                                identity: identity,
                            }),
                        });
                    } catch (error) {
                        console.log(error)
                    }

                }
            }
        })
    }
}