import { ConnectionStatus } from 'botframework-directlinejs';
import {
    getCookie,
    getLocationInformation,
    reportAnalytics,
    reportCookie,
} from './ChatAnalytics';

const INTEGRATION_NUDGE_URL =
    process.env.REACT_APP_CHATDB_ENDPOINT + '/api/v2/nudge/';

export default class Analytics {
    constructor(integrationSettings, iid, host, path, title) {
        this.integrationSettings = integrationSettings;

        this.iid = iid;
        this.host = host;
        this.path = path;
        this.title = title;
    }

    setupAnalytics(directline) {
        directline.connectionStatus$.subscribe((connectionStatus) => {
            if (connectionStatus === ConnectionStatus.Online) {
                if (this.integrationSettings.enable_analytics) {
                    reportAnalytics(
                        directline.conversationId,
                        this.iid,
                        this.host,
                        this.path,
                        this.title
                    );
                }

                if (this.integrationSettings.enable_cookies) {
                    reportCookie(directline.conversationId, this.iid);
                }
            }
        });
    }

    async startNudges() {
        let location = await getLocationInformation();

        let nudges = await fetch(INTEGRATION_NUDGE_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                bot_web_key: this.iid,
                page_url: this.host + this.path,
                page_title: this.title,
                cookie: this.integrationSettings.enable_cookies
                    ? getCookie(this.iid)
                    : undefined,
                location_country: location.countryCode,
            }),
        });

        let nudgeSets = await nudges.json();
        if (Array.isArray(nudgeSets)) {
            nudgeSets.forEach((nudgeSet) => this.queueNudgeSet(nudgeSet));
        }
    }

    queueNudgeSet(nudgeSet) {
        setTimeout(() => this.sendNudges(nudgeSet.nudges), nudgeSet.delay_ms);
    }

    sendNudges(nudges) {
        this.postMessage('nudges:' + JSON.stringify(nudges));
    }

    /**
     * Posts a message to the parent window, if this window has one
     * @param {*} msg
     */
    postMessage(msg) {
        if (window.parent) {
            window.parent.postMessage(msg, '*');
        }
    }
}
