import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import ChatApp from './ChatApp';
import './index.css';




/**
 * Accesses data handed into frame via URL
 * iid - required
 */
function decodeURL()    {
    if (!window.location.href.split('?')[1])    {
        console.log("No data passed in to Chat element :(.");
        return;
    }
    var params = window.location.href.split('?')[1].split('&');
    var data = {};
    for (var x in params)   {
        data[params[x].split('=')[0]] = decodeURIComponent(params[x].split('=')[1]);
    }
    return data;
}
var iid, mobile;
var data = decodeURL();
if (data)   {
    iid = data.iid;
    mobile = data.mobile === "true" ? true : false;
}

// Try fish an iid from end of url
if (!iid)   {
    var url = window.location.href;
    if (url.endsWith('/'))  {
        url = url.substr(0, url.length - 1);
    }
    var last = url.substring(url.lastIndexOf('/') + 1);
    if (/^[a-zA-Z]*$/.test(last))   {
        iid = last;
    }
}

// Make sure frame is open when the bot is being interacted with (see chatplugin.js)
window.addEventListener("click", () => {
    window.postMessage("chatwindowOpen", "*");
});

ReactDOM.render(
    iid ? 
        <ChatApp 
            iid={iid}
            mobile={mobile}
            path={data.path}
            host={data.host}
            title={data.title}/>
    :
        <div className='noiid-error'> Error, please use a url with an iid </div>
, document.getElementById('root'));