import React, { Component } from 'react';
import "./CustomHeroCard.css";

export default class CustomHeroCard extends Component {
    constructor(props) {
        super(props);

        this.action = this.action.bind(this);
    }

    action(button) {
        if (button.type === 'imBack') {
            this.props.api.sendActivity({
                type: 'WEB_CHAT/SEND_MESSAGE',
                payload: {
                    text: button.value
                }
            });
        } else if (button.type.toLowerCase() === 'openurl') {
            window.open(
                button.value,
                '_blank' // <- This is what makes it open in a new window.
            );
            // if (window.parent) {
            //     window.parent.postMessage(`openUrl:${button.value}`, '*');
            // } else {
            //     window.open(
            //         button.value,
            //         '_blank' // <- This is what makes it open in a new window.
            //     );
            //     // window.location = button.value;
            // }
        }
    }

    render() {
        const herocard = this.props.herocard;
        let imageUrl;
        if (herocard.images && herocard.images.length > 0) {
            imageUrl = herocard.images[0].url;
        }

        let webConfig = herocard.webConfig ?? {};

        return (
            <div className='herocard-container'>
                { imageUrl ?
                    <img
                        className='herocard-image'
                        src={imageUrl}
                        alt=""
                    />
                    : ''
                }
                { herocard.title || herocard.text ?
                    <div className='herocard-text-area'>
                        {herocard.title ?
                            <div className='ac-textBlock herocard-title-text'>
                                {herocard.title}
                            </div>
                            : ''
                        }
                        {herocard.text ?
                            <div className='ac-textBlock'>
                                {herocard.text}
                            </div>
                            : ''
                        }
                    </div>
                    : ''
                }
                {
                    herocard.buttons && webConfig.actionsRow ?
                        <div className='herocard-button-row-container'>
                            {herocard.buttons.map(btn => (
                                <div className='herocard-button herocard-button-row'
                                    key={btn.title}
                                    onClick={() => { this.action(btn) }}>
                                    { btn.title}
                                </div>
                            ))}
                        </div>
                        : ''
                }
                {
                    herocard.buttons && !webConfig.actionsRow ?
                        <div className='herocard-button-col-container'>
                            {herocard.buttons.map(btn => (
                                <div className='herocard-button herocard-button-col'
                                    key={btn.title}
                                    onClick={() => { this.action(btn) }}>
                                    { btn.title}
                                </div>
                            ))}
                        </div>
                        : ''
                }
            </div>
        )
    }
}