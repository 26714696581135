export default class DynamicUIModification {
  suggestionUiChanges(sugUi) {
    if (sugUi) {
      // console.log(sugUi)
    }
  }
  fontFamily(fonts) {
    return fonts.map((font) => `'${font}'`).join(", ");
  }
  createCssRules(uiProps) {
    if (uiProps.integrationSettings.color.suggestion_button_hover) {
      document.styleSheets[1].insertRule(
        `
            .webchat__suggested-action__button:hover {
                background-color: ${uiProps.integrationSettings.color.suggestion_button_hover} !important;
            }
        `,
        0
      );
      document.styleSheets[1].insertRule(
        `
            .webchat__suggested-action__button {
                border-width:${
                  uiProps.integrationSettings.color
                    .suggestion_button_border_width + "px" ?? "1px"
                } !important;
               }
        `,
        0
      );
    }

    document.styleSheets[1].insertRule(
      `
        .herocard-button {
            background-color: ${
              uiProps.integrationSettings.color.herocard_button ??
              "rgba(198, 201, 201, 0.14)"
            };
            color: ${
              uiProps.integrationSettings.color.herocard_button_text ??
              "#4a5051"
            };
        }
    `,
      0
    );
    document.styleSheets[1].insertRule(
      `
        .herocard-title-text {
            color: ${
              uiProps.integrationSettings.color.herocard_title_text
            };
        }
    `,
      0
    );
    document.styleSheets[1].insertRule(
      `
        .herocard-button:hover {
            background-color: ${
              uiProps.integrationSettings.color.herocard_button_hover ??
              "#80CEEC"
            } !important;
        }
    `,
      0
    );
    document.styleSheets[1].insertRule(
      `
    .headerButton-icon {
        fill: ${
          uiProps.integrationSettings.color.header_buttons ?? "#80CEEC"
        } !important;
    }
    `,
      0
    );
    document.styleSheets[1].insertRule(
      `
    .headerButton-icon:hover {
      fill: ${
        uiProps.integrationSettings.color.header_buttons_hover ?? "#80CEEC"
      } !important;
    }
     `,
      0
    );
    document.styleSheets[1].insertRule(
      `
    .bottomButton-icon {
        fill: ${
          uiProps.integrationSettings.color.bottom_bar_buttons ?? "#80CEEC"
        } !important;
    }
    `,
      0
    );
    document.styleSheets[1].insertRule(
      `
    .bottomButton-icon:hover {
      fill: ${
        uiProps.integrationSettings.color.bottom_bar_buttons_hover ?? "#80CEEC"
      } !important;
    }
     `,
      0
    );

    document.styleSheets[1].insertRule(
      `
     .webchat__send-icon {
        opacity:1;
       fill: ${
         uiProps.integrationSettings.color.bottom_bar_buttons ?? "#80CEEC"
       } !important;
       cursor: pointer;
       margin-bottom:5px;
      
     }
      `,
      0
    );
    document.styleSheets[1].insertRule(
      `
      .webchat__send-icon:hover {
        fill: ${
          uiProps.integrationSettings.color.bottom_bar_buttons_hover ??
          "#80CEEC"
        } !important;
        
      }
       `,
      0
    );
    setInterval(() => {
      let sendButtonDiv = document.getElementsByClassName(
        "webchat__icon-button"
      );
      if (sendButtonDiv.length !== 0) {
        sendButtonDiv[0].innerHTML =
          "<svg xmlns='http://www.w3.org/2000/svg' height='28' width='28' class='webchat__send-icon' viewBox='0 0 45.7 33.8'><defs>" +
          "</defs><path class='a' d='M8.72,14.85v2.51a3.11,3.11,0,0,0,2.3,3L16.86,22a3.12,3.12,0,0,1,0,6L11,29.63a3.11,3.11,0,0,0-2.3,3v2.51A3.13,3.13,0,0,0,13,38.07l26.3-10.15a3.13,3.13,0,0,0,0-5.84L13,11.93A3.13,3.13,0,0,0,8.72,14.85Z'/>" +
          "</svg>";
      }
    }, 1000);
  }
  //advance Bot Style
  advanceBotStyle(uiProps) {
    var style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);

    style.sheet.insertRule(
      `.ac-pushButton.style-default {
            background: ${uiProps.integrationSettings.color.bot_button}!important
            color: ${uiProps.integrationSettings.color.bot_button_text}!important;
        `,
      0
    );

    var insertNewStyle = document.createElement("style");
    insertNewStyle.appendChild(document.createTextNode(""));
    document.head.appendChild(insertNewStyle);

    if (uiProps.integrationSettings.chatbox) {
      var chatbox = uiProps.integrationSettings.chatbox;
      if (chatbox.height && chatbox.width) {
        insertNewStyle.sheet.insertRule(
          `.chat_window {
                    width: ${chatbox.width}px;
                    height: ${chatbox.height}px;
                }
                `,
          0
        );
      }

      if (chatbox.hideTextBar === true) {
        style.sheet.insertRule(
          `.main{
                    display:none !important;
                    
                }`,
          0
        );
        style.sheet.insertRule(
          `.global-menu-button{ 
                    display:none !important;
                   
                }`,
          0
        );
      }

      if (chatbox.transparent === true) {
        insertNewStyle.sheet.insertRule(
          `.chat_box {
                    background:transparent
                }`,
          0
        );
      } else if (chatbox.backgroundImage) {
        insertNewStyle.sheet.insertRule(
          `.chat_box {
                    background-image: url(${chatbox.backgroundImage});
                        background-position: center;
                         background-size: cover;
                }`,
          0
        );
      } else {
        insertNewStyle.sheet.insertRule(
          `.chat_box {
                    background: ${uiProps.integrationSettings.color.bot_background}
                }`,
          0
        );
      }
    }
  }
  //Need to call only this in Chat Window
  processStyleOptions(uiProps) {
    this.createCssRules(uiProps);
    this.advanceBotStyle(uiProps);
    this.suggestionUiChanges(uiProps.extensions.SUGGESTION_UI);
    return {
      backgroundColor: uiProps.integrationSettings.chatbox
        ? "transparent"
        : uiProps.integrationSettings.color.bot_background,

      bubbleBorderRadius: 8,
      bubbleBackground: uiProps.integrationSettings.color.bot_bot,
      bubbleTextColor: uiProps.integrationSettings.color.bot_bot_text,

      bubbleFromUserBorderRadius: 8,
      bubbleFromUserBackground: uiProps.integrationSettings.color.bot_user,
      bubbleFromUserTextColor: uiProps.integrationSettings.color.bot_user_text,

      paddingWide: 20,

      hideUploadButton: true,

      primaryFont: this.fontFamily([
        "Open sans",
        "Calibri",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ]),
      suggestedActionLayout: "stacked",
      suggestedActionHeight: 30,
      suggestedActionBorderRadius: "50vh",
      suggestedActionBorderWidth:
        uiProps.integrationSettings.color.suggestion_button_border_width ?? 0,
      suggestedActionBorderColor:
        uiProps.integrationSettings.color.suggestion_button_border_color,
      suggestedActionBackground:
        uiProps.integrationSettings.color.suggestion_button,
      suggestedActionTextColor:
        uiProps.integrationSettings.color.suggestion_button_text,
      // suggestedActionsStackedHeight: '10px !important',
      // suggestedActionsStackedOverflow: 'hidden !important'
    };
  }
}
