import * as iplocation from 'iplocation';
import Cookies from 'js-cookie';

const ANALYTICS_URL =
    process.env.REACT_APP_CHATDB_ENDPOINT + '/api/v2/analytics/';
const COOKIE_URL =
    process.env.REACT_APP_CHATDB_ENDPOINT + '/api/v2/analytics/cookie';
const COOKIE_NAME = 'FriendlyBotCookieFromAko';

export async function reportAnalytics(
    conversationId,
    integrationId,
    host,
    path,
    title
) {
    let data = [
        ['user_agent', window.navigator.userAgent],
        ['page_domain', host],
        ['page_url', path],
        ['page_title', title],
    ];

    fetch(ANALYTICS_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            bot_web_key: integrationId,
            conversation_id: conversationId,
            data: data.filter((entry) => entry[1]),
        }),
    });
}

export async function getLocationInformation() {
    let cloudflareInfo = await fetch(
        'https://www.cloudflare.com/cdn-cgi/trace'
    );
    let lines = await cloudflareInfo.text();
    let ip = lines
        .split('\n')
        .filter((line) => line.startsWith('ip='))[0]
        .substring(3);

    let result;
    try {
        result = await iplocation(ip);
    } catch (e) {
        result = {
            country: 'Unknown',
            region: 'Unknown',
            city: 'Unknown',
            countryCode: '-',
        };
    }

    return {
        country: result.country,
        region: result.region,
        city: result.city,
        countryCode: result.countryCode,
    };
}

export async function reportCookie(conversationId, integrationId) {
    let cookie = getCookie(integrationId);

    fetch(COOKIE_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            bot_web_key: integrationId,
            conversation_id: conversationId,
            cookie: cookie,
        }),
    });
}

export function getCookie(integrationId) {
    let cookie = Cookies.get(integrationId + '_' + COOKIE_NAME);

    if (!cookie) {
        cookie = makeCookie();
        Cookies.set(integrationId + '_' + COOKIE_NAME, cookie);
    }

    return cookie;
}

function makeCookie() {
    const validChars =
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let cookie = '';
    for (let i = 0; i < 512; i++) {
        cookie += validChars[Math.floor(Math.random() * validChars.length)];
    }
    return cookie;
}
