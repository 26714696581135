import ReactWebChat from "botframework-webchat";
import React, { Component } from "react";
import "./bootstrap.min.css";
import "./ChatWindow.css";
import ChatWindowParticipantTagger from "./ChatWindowParticipantTagger";
import ChatWindowTypingIndicator from "./ChatWindowTypingIndicator";
import "./DynamicCSS/ChatWindowMSOverrides.css";
import DynamicUIModification from "./DynamicCSS/DynamicUIModification";
import { AttachmentMiddleware } from "./Extensions/AttachmentMiddleware";
import GlobalMenuExtension, {
  GLOBAL_MENU_EXT_ID,
} from "./Extensions/GlobalMenuExtension/GlobalMenuExtension";
import WebViewExtension, {
  WEB_VIEW_EXT_ID,
} from "./Extensions/WebViewExtension/WebViewExtension";
import WebViewExternalExtension, {
  WEB_VIEW_EX_EXT_ID,
} from "./Extensions/WebViewExternalExtension/WebViewExternalExtension";

let uiStyle = new DynamicUIModification();
export default class ChatWindow extends Component {
  constructor(props) {
    super(props);

    this.props.setBubbleToShrinkIn();

    this.state = {
      ask_close: false,
      ask_minimize: false,
    };

    this.state.confirm_cookies =
      localStorage.getItem(this.props.cookies_state_store) !== "yes";
    this.minimize_clicked = () => {
      this.setState({
        ask_minimize: !this.state.ask_minimize,
        ask_close: false,
      });
    };
    this.end_clicked = () => {
      this.setState({ ask_close: !this.state.ask_close, ask_minimize: false });
    };
    this.close_confirmed = () => {
      this.props.endClicked();
    };
    this.close_cancel = () => {
      this.setState({ ask_close: false });
    };
    this.close_clicked = () => {
      this.minimize_clicked();
      this.props.onClosed();
    };
    this.cookies_understood = () => {
      localStorage.setItem(this.props.cookies_state_store, "yes");
      this.setState({ confirm_cookies: false });
    };
    this.hideBot = () => {
      window.parent.postMessage("HideBot", "*");
      this.props.onClosed();
      localStorage.setItem(this.props.botid + "-hideBotCID", Date.now());
    };
    this.cookies_details = () => {
      // window.parent.postMessage(
      //   `openUrl:${this.props.integrationSettings.web_cookies_url}`,
      //   "*"
      // );
      window.open(this.props.integrationSettings.web_cookies_url, "_blank");
    };

    this.poweredby_site = () => {
      if (window.parent) {
        window.parent.postMessage(
          `openUrl:${this.props.integrationSettings.web_poweredby_url}`
        );
      } else {
        window.location = this.props.integrationSettings.web_poweredby_url;
      }
    };

    // Bot style rules
    this.state.styleOptions = uiStyle.processStyleOptions(this.props);

    // Adds bot/user tags and icon to conversation blocks
    let tagger = new ChatWindowParticipantTagger(
      this.props.integrationSettings.bot_name,
      this.props.integrationSettings.bot_icon_url
    );
    tagger.start();
  }

  componentDidUpdate(oldProps) {
    if (this.props.opened === false && oldProps.opened === true) {
      this.didClose();
    }

    let oldIntegrationJson = JSON.stringify(oldProps.integrationSettings);
    let newIntegrationJson = JSON.stringify(this.props.integrationSettings);
    if (oldIntegrationJson !== newIntegrationJson) {
      this.setState({ styleOptions: uiStyle.processStyleOptions(this.props) });
    }
  }

  didClose() {
    this.setState({
      ask_close: false,
      minimize_clicked: false,
    });
  }

  getChatWindowClasses(mobile, left) {
    let classes = ["chat_window"];

    if (mobile) {
      classes.push("chat_window_mobile");
    }

    if (left) {
      classes.push("chat_window_left");
    }

    return classes.join(" ");
  }
  getChatWindowHeightProperty(mobile) {
    if (mobile) {
      return this.props.integrationSettings.mobile.height
        ? this.props.integrationSettings.mobile.height
        : "100%";
    }
    return undefined;
  }

  render() {
    var { store, directline, inputEnabled, mobile, opened, connectionError } =
      this.props;
    var { ask_close, confirm_cookies, styleOptions, ask_minimize } = this.state;
    return (
      <div
        className={this.getChatWindowClasses(
          mobile,
          this.props.integrationSettings.align_right === false
        )}
        style={{
          display: opened ? "block" : "none",
          height: this.getChatWindowHeightProperty(mobile),
        }}
      >
        <div
          className={mobile ? "chat_header chat_header_mobile" : "chat_header"}
          style={{
            backgroundColor: this.props.integrationSettings.color.header,
            color: this.props.integrationSettings.color.header_text,
          }}
        >
          <div
            className="chat_header_icon"
            style={{
              backgroundImage: `url(${this.props.integrationSettings.bot_icon_url})`,
            }}
          ></div>
          <div
            className="chat_header_btn_close"
            onClick={() => {
              this.props.integrationSettings.chatbox &&
              this.props.integrationSettings.chatbox.AllowUserToHideBot
                ? this.minimize_clicked()
                : this.close_clicked();
            }}
          >
            {/* this.close_clicked */}
            <svg className="headerButton-icon">
              <use xlinkHref="/res/defs.svg#svg-icon-minus"></use>
            </svg>
          </div>
          <div
            className="chat_header_btn_end"
            onClick={() => {
              this.end_clicked();
            }}
          >
            <svg className="headerButton-icon">
              <use xlinkHref="/res/defs.svg#svg-icon-times"></use>
            </svg>
          </div>
          <div className="chat_header_labels">
            <span className="chat_header_title" style={{
            fontSize:
                  this.props.integrationSettings.headerTitleSize === "small"
                    ? 15
                    : this.props.integrationSettings.headerTitleSize === "medium"
                    ? 20
                    : this.props.integrationSettings.headerTitleSize === "large"
                    ? 24
                    : 24
              }}>
              {" "}
              {this.props.integrationSettings.bot_name}{" "}
            </span>{" "}
            <br />
            <span className="chat_header_subtitle" style={{
                fontSize:
                  this.props.integrationSettings.headerTitleSize === "small"
                    ? 10
                    : this.props.integrationSettings.headerTitleSize === "medium"
                    ? 15
                    : this.props.integrationSettings.headerTitleSize === "large"
                    ? 19
                    : 19 
              }} 
            >
              {" "}
              {this.props.integrationSettings.bot_tagline}{" "}
            </span>
          </div>
        </div>
        <div className="chat_popup_area">
          {ask_close ? (
            <div className="chat_popup" style={{ zIndex: 6 }}>
              <div className="chat_popup_text"> End the chat? </div>{" "}
              <div className="chat_popup_action">
                <div
                  className="btn btn-outline-success mr-2 btn_popup"
                  onClick={this.close_cancel}
                >
                  {" "}
                  Cancel{" "}
                </div>
                <div
                  className="btn btn-outline-danger mr-2 btn_popup"
                  onClick={this.close_confirmed}
                >
                  {" "}
                  End Chat{" "}
                </div>
              </div>
            </div>
          ) : null}
          {confirm_cookies &&
          this.props.integrationSettings.web_cookies_url !== "" ? (
            <div className="chat_popup" style={{ zIndex: 5, padding: "0.5em" }}>
              <div
                className="chat_popup_text"
                style={{ width: "70%", float: "left" }}
              >
                {" "}
                This digital assistant uses cookies to ensure you get the best
                experience.{" "}
                <span className="fake_link" onClick={this.cookies_details}>
                  {" "}
                  Learn more{" "}
                </span>{" "}
              </div>
              <div
                style={{ float: "right" }}
                className="btn btn-outline-primary float-right mr-2 btn_popup"
                onClick={this.cookies_understood}
              >
                {" "}
                X{" "}
              </div>
            </div>
          ) : (
            ""
          )}
          {ask_minimize &&
          this.props.integrationSettings.chatbox.AllowUserToHideBot ? (
            <div className="chat_popup" style={{ zIndex: 6 }}>
              <div className="chat_popup_text">
                {" "}
                Hide bot option will hide your bot for 15 Minutes{" "}
              </div>{" "}
              <div className="chat_popup_action">
                <div
                  className="btn btn-outline-success mr-2 btn_popup"
                  onClick={this.close_clicked}
                >
                  {" "}
                  Minimize{" "}
                </div>
                <div
                  className="btn btn-outline-danger mr-2 btn_popup"
                  onClick={this.hideBot}
                >
                  {" "}
                  Hide{" "}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="chat_box_outer"
          style={{ height: mobile ? "100%" : "102%" }}
        >
          <div
            className="chat_box_connection_error"
            style={{ display: connectionError ? "block" : "none" }}
          >
            <div className="chat_box_connection_error_inner">
              <span
                className="connection_error_icon"
                role="img"
                aria-label="Warning"
              >
                {" "}
                ⚠️{" "}
              </span>{" "}
              <p />
              <span className="connection_error_text_small">
                Your session has expired due to inactivity
              </span>{" "}
              <br />
              <div
                className="btn btn-outline-muted mt-4 connection_error_restart"
                onClick={this.close_confirmed}
              >
                {" "}
                Close Chat{" "}
              </div>
            </div>
          </div>
          <div className="chat_box" id="chat_box">
            {directline ? (
              <ReactWebChat
                directLine={directline}
                styleOptions={styleOptions}
                store={store}
                disabled={!inputEnabled}
                typingIndicatorMiddleware={ChatWindowTypingIndicator}
                attachmentMiddleware={AttachmentMiddleware(this.props.api)}
              />
            ) : (
              <div className="chat_loading">Loading...</div>
            )}
            {/* { //@TODO automate extension management
                            this.props.extensions[CHAT_SUGGESTION_EXT_ID] ?
                                <ChatSuggestionExtension
                                    suggestions={this.props.extensions[CHAT_SUGGESTION_EXT_ID]}
                                    api={this.props.extensions._api}
                                    styling={this.props.integrationSettings}
                                    opened={opened}
                                />
                                : ''
                        } */}
            {this.props.extensions[GLOBAL_MENU_EXT_ID] ? (
              <GlobalMenuExtension
                menu={this.props.extensions[GLOBAL_MENU_EXT_ID]}
                api={this.props.extensions._api}
                styling={this.props.integrationSettings}
                opened={opened}
                closed={this.end_clicked}
              />
            ) : (
              ""
            )}
            {this.props.extensions[WEB_VIEW_EXT_ID] ? (
              <WebViewExtension
                webview={this.props.extensions[WEB_VIEW_EXT_ID]}
                api={this.props.extensions._api}
                styling={this.props.integrationSettings}
                opened={opened}
              />
            ) : (
              ""
            )}
            {this.props.extensions[WEB_VIEW_EX_EXT_ID] ? (
              <WebViewExternalExtension
                webviewExt={this.props.extensions[WEB_VIEW_EX_EXT_ID]}
                api={this.props.extensions._api}
                styling={this.props.integrationSettings}
                opened={opened}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <span className="poweredby_tag" onClick={this.poweredby_site}>
          {" "}
          {this.props.integrationSettings.web_poweredby_text1}{" "}
          <span className="poweredby_tag_title">
            {" "}
            {this.props.integrationSettings.web_poweredby_text2}{" "}
          </span>{" "}
        </span>
      </div>
    );
  }
}
