import React from 'react';
import './ChatWindowTypingIndicator.css';


export default () => next => ({ activeTyping }) => {
    activeTyping = Object.values(activeTyping);
    if (!!activeTyping.length) {
        addTypingIndicator();
    } else {
        removeTypingIndicator();
    }

    return (!!activeTyping.length && (
        <div className="ako-webchat-typing-indicator">
        </div>
    ))
}

function addTypingIndicator() {
    let messageList = document.querySelector("#chat_box .webchat__basic-transcript div ul");

    if (!messageList) {
        console.error(`Typing indicator: couldn't find messageList`);
        return;
    }

    let messages = messageList.children;
    let last = messages[messages.length - 1];

    if (!last || !last.querySelector('.typing-indicator-inline')) {
        messageList.appendChild(getTypingBox())
    }
}

function removeTypingIndicator() {
    let indicator = document.querySelector(".typing-indicator-inline");

    if (indicator) {
        // indicator.style.height = '0px'
        // indicator.style.width = '0px'
        indicator.style.opacity = 0
        setInterval(() => indicator.remove(), 20)
        // indicator.addEventListener('transitionend', function () {
        //     indicator.style.opacity = 0
        //     indicator.remove()
        //     setInterval(() => indicator.remove(), 5)
        // })
        // indicator.classList.add('hello')
        // console.log(indicator.classList);
        // indicator.classList.remove('hello')
        // console.log(indicator.classList)
        // setInterval(()=>indicator.parentNode.removeChild(indicator))

    }
}

function getTypingBox() {
    let tag = document.createElement("li");
    tag.innerHTML = `
        <div class="typing-indicator-inline">
            <div class="typing-indicator-disp">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    `;
    tag.className = "";
    tag.setAttribute('role', 'listitem');
    return tag;
}