import React, { Component } from 'react';
import "./GlobalMenuExtension.css";


export const GLOBAL_MENU_EXT_ID = "GLOBAL_MENU";

export default class GlobalMenuExtension extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            path: []
        }

        this.openMenu = this.openMenu.bind(this);
        this.openItem = this.openItem.bind(this);
        this.upLevel = this.upLevel.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);      
    }
    componentDidMount() {
        this.setupStyles();
    }
    setupStyles() {
        if (!this.props.menu) {
            return;
        }
        let inputBox = document.querySelector('[role=form] .main');
        if (!inputBox) {
            setTimeout(this.setupStyles.bind(this), 10);
            return;
        }

        inputBox.style.paddingLeft = '40px';
    }

    openMenu() {
        (!this.state.isOpen)
            ? document.addEventListener('click', this.handleOutsideClick, false)
            : document.removeEventListener('click', this.handleOutsideClick, false)

        this.setState({ isOpen: !this.state.isOpen });
    }

    handleOutsideClick(e) {
        if (this.node.contains(e.target)) {
            return;
        }
        this.openMenu();
    }

    openItem(item, index) {
        if (item.action === undefined) {
            if (item.items) {
                setTimeout(() => { this.setState({ path: [...this.state.path, index], isOpen: true }) }, 10)

            } else if (item.value) {
                this.props.api.sendActivity({
                    type: 'WEB_CHAT/SEND_MESSAGE',
                    payload: { text: item.value }
                });
                this.openMenu()
            }
        }
        else {
            switch (item.action) {
                case "close":
                    this.props.closed();
                    break;

                default:
                    break;
            }
            this.openMenu()
        }

    }
    upLevel() {
        if (this.state.path.length > 0) {

            setTimeout(() => { this.setState({ path: [...this.state.path.slice(0, -1)], isOpen: true }) }, 10);
        }
    }
    renderMenuIcon() {
        return (this.state.isOpen)
            ? <svg className='bottomButton-icon' width="20" height="25" >
                <use xlinkHref="/res/defs.svg#svg-icon-times"></use>
            </svg>
            : <svg viewBox="0 0 100 80" className='bottomButton-icon' width="20" height="25">
                <rect width="100" height="20"></rect>
                <rect y="30" width="100" height="20"></rect>
                <rect y="60" width="100" height="20"></rect></svg>

    }
    render() {
        let currentMenu = this.props.menu;
        let path = this.state.path;
        for (let part of path) {
            currentMenu = currentMenu.items[part];
        }
        let items = currentMenu.items;
        return <div ref={node => { this.node = node; }}>
            <div className='global-menu-button' onClick={this.openMenu}>
                {this.renderMenuIcon()}
            </div>
            {this.state.isOpen ?
                <div className='global-menu-container' >
                    {
                        this.state.path.length > 0 ? <div className='global-menu-title global-menu-title-exitable'
                            onClick={this.upLevel}>
                            <div> <div className='global-menu-title-exit'> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill='grey' style={{ fontWeight: '0', marginLeft: '15px' }} viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" /></svg>
                            </div>{currentMenu.title}</div>
                        </div> : ''
                    }
                    <div className='animated'>
                        {
                            items.map((item, index) =>
                                <div className='global-menu-item' key={index} onClick={() => this.openItem(item, index)}>
                                    <div>
                                        <img className="action__image " alt='AI' src={(item.icon === undefined || item.icon === null) ? "https://static.infra.ako.ai/ako/goto.png" : item.icon} /> {item.title}</div>
                                    {item.items ?
                                        <div><div className='global-menu-item-expand'> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill='grey' style={{ fontWeight: '0' }}><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /></svg> </div>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>)
                        }</div>
                </div>
                : ''}
        </div>
    }
}